<script>
import SearchResultCard from '@/components/discovery/SearchResultCard'
import EmptyList from '@/components/general/EmptyList.vue'

export default {
  name: 'DiscoveryPositionsDefault',
  components: {
    SearchResultCard,
    EmptyList
  },
  data () {
    return {
      filteredList: null
    }
  },
  methods: {
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    },
    showModalContentDetails (params) {
      this.$emit('showModalContentDetails', params)
    }
  },
  created () {
    this.$store.dispatch('attemptGetPositionSuggestionsHome', { user: this.getUserInfo, aggregator: this.getCompanyDomainAndWorkspace() })
      .then(res => {
        this.filteredList = res.map(item => {
          if (!item.isFavorite) item.isFavorite = false
          return item
        })
      })
  }
}
</script>
<template>
  <div class="discovery-default--container">
    <div v-if="filteredList && filteredList.length">
      <search-result-card v-for="(filtedItem, i) in filteredList" :key="i" :items="filtedItem"
        type="positions" />
    </div>
    <div class="margin-empty" v-else-if="filteredList && filteredList.length === 0">
      <empty-list class="margin-empty" :title="$t('empty.state.title:empty.title')"
        :image="'/assets/images/empty-box.png'"></empty-list>
    </div>
  </div>
</template>
